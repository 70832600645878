<template>
  <v-row align="center" justify="center">
    <v-col cols="12">
      <v-card elevation="0" tile>
        <v-card-title class="grey lighten-4">
          <employee-picker v-model="table.criteria.employees" multiple style="max-width: 400px" />
          <v-spacer />
          <id-button @click="onRefreshData">
            <template #icon>
              <v-icon left>mdi-refresh</v-icon>
            </template>
            {{ $t("buttons.refresh") }}
          </id-button>
        </v-card-title>
        <v-data-table
          :headers="table.headers"
          :items="filteredItems"
          single-select
          class="elevation-1"
          :loading="table.loading"
          :footer-props="{ 'items-per-page-options': [10, 25, 50, 100] }"
        >
          <template slot="item" slot-scope="row">
            <tr :key="row.item.id">
              <td class="no-wrap">
                <employee-profile :employee="row.item.travel.employee" />
              </td>
              <td class="no-wrap">
                {{ row.item.travel.beginDate | formattedDateTimeMinute }}
              </td>
              <td class="no-wrap">
                {{ row.item.travel.endDate | formattedDateTimeMinute }}
              </td>
              <td class="no-wrap">
                {{ row.item.travel.startWorkDate | formattedDate }}
              </td>
              <td class="no-wrap">
                {{ row.item.travel.address }}
              </td>
              <td class="text-center no-wrap">
                <request-status-label :status="row.item.travel.requestStatus" />
              </td>
              <td class="text-right no-wrap">
                <v-btn small depressed color="primary" dark @click.stop="onClickExpenseApproval(row.item)">
                  {{ $t("buttons.action") }}
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
    <travel-approval-dialog
      v-model="dialog.status"
      :item.sync="dialog.data"
      @close="onCloseDialog"
      @success="onSuccessApproval"
    />
  </v-row>
</template>

<script>
  import * as GQL from "./query";

  export default {
    name: "TravelApproval",
    components: {
      RequestStatusLabel: () => import("@/components/global/request-status-label"),
      TravelApprovalDialog: () => import("./components/confirm-dialog")
    },
    data: (vm) => ({
      dialog: {
        status: false,
        data: null
      },
      table: {
        loading: false,
        data: [],
        criteria: { employees: [] },
        selected: [],
        headers: [
          {
            text: vm.$t("hr.travel_request.employee"),
            align: "left",
            sortable: false,
            value: "",
            class: ["primary--text text-lg-left"]
          },
          {
            text: vm.$t("hr.travel_request.begin_date"),
            align: "left",
            sortable: false,
            value: "",
            class: ["primary--text text-lg-left"]
          },
          {
            text: vm.$t("hr.travel_request.end_date"),
            align: "left",
            sortable: false,
            value: "",
            class: ["primary--text text-lg-left"]
          },
          {
            text: vm.$t("hr.travel_request.start_work_date"),
            align: "left",
            sortable: false,
            value: "",
            class: ["primary--text text-lg-left"]
          },
          {
            text: vm.$t("global.address"),
            align: "left",
            sortable: false,
            value: "",
            class: ["primary--text"]
          },
          {
            text: vm.$t("hr.travel_request.status"),
            align: "center",
            sortable: false,
            value: "",
            class: ["primary--text"]
          },
          {
            text: "",
            align: "left",
            sortable: false,
            value: "",
            class: ["primary--text text-right"]
          }
        ]
      }
    }),
    computed: {
      filteredItems() {
        return this.table.data.filter(({ paperRequest }) => {
          const { employees } = this.table.criteria;
          const isEmployeeSelected = employees.length > 0 ? employees.includes(paperRequest.employee.id) : true;

          return isEmployeeSelected;
        });
      }
    },
    methods: {
      onRefreshData() {
        this.fetchItems();
      },
      async fetchItems() {
        this.table.loading = true;
        await this.$apollo
          .query({
            query: GQL.PENDING_TRAVEL_REQUESTS,
            variables: {
              id: this.$store.state.auth.user.id
            },
            fetchPolicy: "no-cache",
            errorPolicy: "all"
          })
          .then(({ data, errors }) => {
            if (!errors || !data.error) {
              this.table.data = data.employee.allPendingApprovalTravelRequests || [];
            }
          })
          .catch((e) => {
            this.$helpers.showNotification(e.message);
          })
          .finally(() => {
            this.table.loading = false;
          });
      },
      onClickExpenseApproval(travel) {
        this.dialog.data = travel;
        this.dialog.status = true;
      },
      onCloseDialog() {
        this.dialog.data = null;
        this.dialog.status = false;
      },
      onSuccessApproval() {
        this.onCloseDialog();
        this.onRefreshData();
      }
    },
    created() {
      this.fetchItems();
    }
  };
</script>

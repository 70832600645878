import gql from "graphql-tag";

export const PENDING_TRAVEL_REQUESTS = gql`
  query employee($id: ID!) {
    employee(id: $id) {
      allPendingApprovalTravelRequests {
        id
        approver {
          id
          firstName
          lastName
          profile {
            imageUrl
          }
          employment {
            branch {
              name
            }
          }
        }
        note
        approved
        nextApprover
        travel {
          id
          beginDate
          endDate
          startWorkDate
          employee {
            id
            firstName
            lastName
            profile {
              imageUrl
            }
            employment {
              branch {
                name
              }
            }
          }
          address
          country
          city
          region
          requestStatus
        }
      }
    }
  }
`;
